<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            Editing contact persons for <span v-if="cleanLocationData">{{cleanLocationData.locationName}}</span>
        </grid-header>
        <data-table
            class="fill full-height-layout border"
            :options="options"
            v-if="url"
            :url="url"
            :quickSearch="filters.search"
        ></data-table>
        <portal to="actionbar">
            <transition name="slide">
                <div v-if="isDirty" class="card-padding">
                    <mercur-button class="btn btn-raised btn-yellow text-uppercase" @click="saveLocationContactPersons" :disabled="loading">Save changes</mercur-button>
                </div>
            </transition>
        </portal>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import LocationBase from '@/views/supplier/locations/LocationBase'
import GridHeader from '../../../components/GridHeader'
import DataTable from '../../../components/DataTable'

export default {
    name: 'SupplierEditLocationContactPersons',
    mixins: [ LocationBase ],
    components: { DataTable, GridHeader },
    data () {
        return {
            shouldGetServiceLevels: false,
            selectedContactPersons: null,
            availableContactPersons: null,
            loading: false,
            isDirty: false,
            url: null,
            filters: {
                search: '',
            },
            options: {
                columns: {
                    'Selected': {
                        field: 'selected',
                        maxWidth: 90,
                        headerComponentFramework: 'CheckboxCell',
                        headerComponentParams: () => {
                            return {
                                disabled: this.loading,
                                setValue: (newValue) => {
                                    if (!newValue) {
                                        this.selectedContactPersons = []
                                        return
                                    }

                                    this.selectedContactPersons = JSON.parse(JSON.stringify(this.availableContactPersons))
                                },
                                getValue: () => {
                                    if (
                                        !this.selectedContactPersons ||
                                        !this.availableContactPersons ||
                                        !this.selectedContactPersons.length ||
                                        !this.availableContactPersons.length
                                    ) {
                                        return false
                                    }
                                    return this.selectedContactPersons.length === this.availableContactPersons.length
                                },
                            }
                        },
                        cellRendererFramework: 'CheckboxCell',
                        cellRendererParams: (params) => {
                            return {
                                getValue: () => {
                                    if (this.selectedContactPersons === null) {
                                        return false
                                    }
                                    return this.selectedContactPersons.includes(params.data.accountId)
                                },
                                setValue: (value) => {
                                    if (!value) {
                                        this.$set(this, 'selectedContactPersons', this.selectedContactPersons.filter(id => id !== params.data.accountId))

                                        return
                                    }
                                    this.selectedContactPersons.push(params.data.accountId)
                                },
                            }
                        },
                    },
                    'Name': {
                        field: 'lastName',
                        valueGetter: ({ data }) => {
                            return `${data.firstName} ${data.lastName}`
                        },
                        sortable: true,
                    },
                    'Email': {
                        field: 'email',
                    },
                    'Phone': {
                        field: 'phone',
                    },
                    'Roles': {
                        field: 'supplierRoles',
                    },
                },
                onDataReceived: (data) => {
                    this.availableContactPersons = data.map(contactPerson => contactPerson.accountId)
                },
            },
        }
    },

    methods: {
        saveLocationContactPersons () {
            const url = CONFIG.API.ROUTES.SUPPLIERS.LOCATION_SET_CONTACT_PERSONS.replace('{supplierId}', this.supplierId).replace('{locationId}', this.$route.params.locationId)
            this.loading = true
            this.addJob(url)
            this.$api.post(url, {
                accountIds: this.selectedContactPersons,
            }).then(({ data }) => {
                this.isDirty = false
                this.$root.$emit('notification:global', {
                    message: `Location contact persons were updated`,
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Setting location contact persons failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
    },

    created () {
        this.supplierData.then(supplierDetails => {
            this.url = CONFIG.API.ROUTES.ACCESS_CONTROL.OVERVIEW_FOR_ORGANISATION.replace('{organisationId}', supplierDetails.organisationId)
        })

        this.locationDetailsPromise.then(() => {
            this.selectedContactPersons = [
                ...(this.selectedContactPersons || []),
                ...(this.cleanLocationData.contactPersonIds ? this.cleanLocationData.contactPersonIds : []),
            ]
            this.$watch('selectedContactPersons', () => {
                this.isDirty = true
            })
        })
    },
}
</script>
