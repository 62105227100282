import { required, email } from 'vuelidate/lib/validators'
export default {
    computed: {
        locationData () {
            return this.$store.getters['suppliers/getLocationBySupplierIdAndLocationId'](this.supplierId, this.$route.params.officeId || this.$route.params.facilityId || this.$route.params.locationId)
        },
        locationId () {
            return this.$route.params.officeId || this.$route.params.facilityId || this.$route.params.locationId
        },
    },

    data () {
        return {
            cleanLocationData: null,
            shouldGetServiceLevels: true,
            invalidProperties: [
                'dateCreated',
                'dateUpdated',
                'dateRevoked',
            ],
            locationDetailsPromise: null,
            locationServiceLevelsPromise: null,
            values: {
                location: {
                    locationName: '',
                    emailAddress: '',
                    country: '',
                    address: '',
                    addressAdditional: '',
                    houseNumber: '',
                    houseNumberAdditional: '',
                    zipCode: '',
                    city: '',
                    phone: '',
                },
                facility: {
                    serviceLevels: [],
                },
            },
        }
    },

    validations () {
        const validations = {
            values: {
                location: {
                    locationName: {
                        required,
                    },
                    emailAddress: {
                        required,
                        email,
                    },
                    country: {
                        required,
                    },
                    address: {
                        required,
                    },
                    houseNumber: {
                        required,
                    },
                    zipCode: {
                        required,
                    },
                    city: {
                        required,
                    },
                },
            },
        }

        if (this.locationType === 'OFFICE') {
            validations.values.office = {
                financeSettings: {
                    bankAccountNumber: {
                        required,
                    },
                    bankName: {
                        required,
                    },
                    bankSwiftCode: {
                        required,
                    },
                    chamberCommerceRegistrationNumber: {
                        required,
                    },
                    currency: {
                        required,
                    },
                    invoiceFrequencyUnit: {
                        required,
                    },
                    invoiceFrequencyValue: {
                        required,
                    },
                    paymentTermsUnit: {
                        required,
                    },
                    paymentTermsValue: {
                        required,
                    },
                    vatNumber: {
                        required,
                    },
                },
            }
        }

        return validations
    },

    watch: {
        locationData: {
            handler (locationData) {
                if (this.formType === 'add' || !locationData) {
                    return
                }

                this.values.location = this.getCleanLocationData()
                this.$set(this.values.location, 'state', 'STATE')

                if (locationData.locationType === 'OFFICE') {
                    this.values.office = this.getCleanLocationData()
                    this.$set(this.values.office, 'certifications', [])
                }
                if (locationData.locationType === 'FACILITY') {
                    this.values.facility = this.getCleanLocationData()
                }

                if (this.$v && this.$v.touch) {
                    this.$v.touch()
                }
            },
            immediate: true,
        },
    },

    methods: {
        getErrorForStep (stepName) {
            if (stepName === 'office') {
                return this.$v.values.location.$anyError ? 'There are errors' : ''
            }
            if (stepName === 'finance') {
                return this.$v.values.office.financeSettings.$anyError ? 'There are errors' : ''
            }

            return ''
        },
        getCleanLocationData () {
            if (!this.cleanLocationData) {
                let locationData = JSON.parse(JSON.stringify(this.locationData))
                locationData = Object.entries(locationData).filter(([key, value]) => !this.invalidProperties.includes(key))
                this.cleanLocationData = Object.assign(...locationData.map(([k, v]) => ({ [k]: v })))
            }

            return this.cleanLocationData
        },
    },

    created () {
        if (this.formType === 'add' && this.locationType === 'OFFICE') {
            this.values.office.financeSettings = {
                bankAccountNumber: null,
                bankName: null,
                bankSwiftCode: null,
                chamberCommerceRegistrationNumber: null,
                currency: null,
                invoiceFrequencyUnit: null,
                invoiceFrequencyValue: null,
                paymentTermsUnit: null,
                paymentTermsValue: null,
                vatNumber: null,
            }
        }
        this.locationDetailsPromise = this.$store.dispatch('suppliers/fetchSupplierLocations', this.supplierId)
        if (this.shouldGetServiceLevels) {
            this.locationServiceLevelsPromise = this.$store.dispatch('suppliers/fetchLocationServiceLevels', {
                supplierId: this.supplierId,
                locationId: this.locationId,
            })

            Promise.all([this.locationDetailsPromise, this.locationServiceLevelsPromise]).then(([locationDetails, serviceLevels]) => {
                this.$set(this.values.facility, 'serviceLevels', serviceLevels)
            })
        }
    },
}
